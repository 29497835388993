<template>
  <div>
    <b-card
      title="รายชื่อสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="userInput"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                label="รายการ"
                label-for="mc-city"
              >
                <b-row>
                  <b-col md="6">
                    <v-select
                      v-model="tableQuery.type"
                      label="title"
                      :options="txtypes"
                    />
                  </b-col>
                  <b-col md="6">
                    <v-select
                      v-model="tableQuery.platform"
                      label="title"
                      :options="platformOpts"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group
                label="วันที่รายการ"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchMemberBetDetail"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-card
      title="รายการฝากถอนล่าสุด"
    >
      <b-table
        ref="wallet_txns_table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="5"
        :current-page="1"
        :items="walletTxn"
        :fields="wallet_txn_fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
      </b-table>
      <div>
        <b-pagination
          v-model="tableQuery.page"
          :total-rows="totalRows"
          :per-page="tableQuery.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <bet-detail-component
      v-if="showBetDetail"
      :user-input="userInput"
      :show="showBetDetail"
      :startdate="tableQuery.startdate"
      :enddate="tableQuery.enddate"
    />
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BFormGroup, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import BetDetailComponent from './BetDetailComponent.vue'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    BFormGroup,
    // BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // vSelect,
    flatPickr,
    BetDetailComponent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      userInput: '',
      tableQuery: {
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      wallet_txn_fields: [
        { key: 'created_at', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน', tdClass: 'td-currency' },
        { key: 'balance', label: 'คงเหลือ', tdClass: 'td-currency' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      walletTxn: [],
      enabledGameProvider: {
        SA: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'platform_datetime', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'txnid', label: 'เลขธุรกรรม' },
            { key: 'gameid', label: 'รอบ/เกมส์' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'result_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas((item.result_amount - item.bet_amount) || 0),
              tdClass: val => (val > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
            { key: 'result', label: 'ผลการเดิมพัน' },
            { key: 'ip', label: 'IP' },
          ],
        },
        SEXY: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            { key: 'txn_id', label: 'เลขธุรกรรม' },
            { key: 'game_id', label: 'รอบ/เกมส์' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: val => this.numberWithCommas(val),
              tdClass: val => (val > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
            { key: 'result', label: 'ผลการเดิมพัน' },
            { key: 'ip', label: 'IP' },
          ],
        },
        WM: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [],
        },
        DG: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            { key: 'ticket_id', label: 'เลขธุรกรรม' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas((item.settle_amount - Math.abs(item.bet_amount)) || 0),
              tdClass: (val, k, item) => ((item.settle_amount - Math.abs(item.bet_amount)) > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
          ],
        },
        JOKER: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'platform_datetime', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'txnid', label: 'เลขธุรกรรม' },
            { key: 'gameid', label: 'รอบ/เกมส์' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'result_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas((item.result_amount - item.bet_amount) || 0),
              tdClass: val => (val > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
            { key: 'result', label: 'ผลการเดิมพัน' },
            { key: 'ip', label: 'IP' },
          ],
        },
        PG: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            { key: 'transaction_id', label: 'เลขธุรกรรม' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas((item.settle_amount - item.bet_amount) || 0),
              tdClass: val => (val > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
          ],
        },
        CQ9: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'mtcode', label: 'เลขธุรกรรม' },
            { key: 'roundid', label: 'รอบ/เกมส์' },
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas(item.settle_amount - item.bet_amount),
              tdClass: (val, k, item) => (item.settle_amount - item.bet_amount > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
            { key: 'result', label: 'ผลการเดิมพัน' },
            { key: 'ip', label: 'IP' },
          ],
        },
        MG: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [],
        },
        JDB: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [],
        },
        SBO: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            { key: 'transfer_code', label: 'เลขตั๋ว' },
            {
              key: 'amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val || 0),
              tdClass: 'td-currency',
            },
            {
              key: 'status',
              label: 'สถานะ',
              formatter: (val, k, item) => (({
                running: 'รอผล',
                settled: 'ผลออกแล้ว',
                void: 'ยกเลิกผล',
                cancelled: 'ยกเลิก',
              })[item.status]),
              tdClass: (val, k, item) => (({
                running: 'text-warning',
                settled: 'text-success',
                void: 'text-danger',
                cancelled: 'text-danger',
              })[item.status]),
            },
            {
              key: 'sbo_betdetail',
              label: 'รายละเอียด',
            },
            {
              key: 'sbo_result',
              label: 'ผล',
            },
            {
              key: 'ip',
              label: 'IP',
            },
          ],
        },
        HOTGRAPH: {
          enable: true,
          items: [],
          totalRows: 0,
          page: 1,
          page_size: 15,
          fields: [
            { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => this.formatDate(val) },
            { key: 'settle_time', label: 'เวลาเคลียร์', formatter: val => this.formatDate(val) },
            { key: 'ref_id', label: 'เลขธุรกรรม' },
            { key: 'round_id', label: 'รอบ/เกมส์' },
            {
              key: 'bet_amount',
              label: 'เงินเดิมพัน',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'settle_amount',
              label: 'เงินเคลียร์',
              formatter: val => this.numberWithCommas(val),
              tdClass: 'td-currency',
            },
            {
              key: 'gross_win',
              label: '+/-',
              formatter: (val, k, item) => this.numberWithCommas((item.settle_amount - Math.abs(item.bet_amount)) || 0),
              tdClass: (val, k, item) => ((item.settle_amount - Math.abs(item.bet_amount)) > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
            },
          ],
        },
      },
      showBetDetail: false,
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined) {
      this.userInput = this.$route.params.user
      this.fetchMemberBetDetail()
    }
  },
  methods: {
    async fetchMemberBetDetail() {
      this.walletTxn = []
      let fieldName = 'phoneno'
      delete this.tableQuery.searchBy
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput
        this.tableQuery.searchBy = 'username'
        delete this.tableQuery.phoneno
      } else if (this.userInput !== '') {
        this.tableQuery.phoneno = this.userInput
        this.tableQuery.searchBy = 'phoneno'
        delete this.tableQuery.username
      }

      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (this.tableQuery[key] === null) return
          if (this.tableQuery[key] === undefined) return
          if (idx > 0) query += '&'
          if ((this.tableQuery[key] || {}).value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      this.showBetDetail = false
      await Promise.all([
        this.fetchMemberWalletTx(query),
      ])
      this.showBetDetail = true
    },
    async fetchMemberWalletTx(query) {
      try {
        const { data: _wallettx } = await this.$http.get(`wallet-transactions${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=1&page_size=15`)
        this.walletTxn = _wallettx.wallet_transactions.data
      } catch (err) {
        this.walletTxn = []
      }
    },
    async fetchSA(query) {
      if (!this.enabledGameProvider.SA.enable)
        return
      this.enabledGameProvider.SA.items = []
      this.enabledGameProvider.SA.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.SA.page}&page_size=15&game_provider=SA`)
        this.enabledGameProvider.SA.items = betDetails.data
        this.enabledGameProvider.SA.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.SA.items = []
        this.enabledGameProvider.SA.totalRows = 0
      }
    },
    async fetchSexy(query) {
      if (!this.enabledGameProvider.SEXY.enable)
        return
      this.enabledGameProvider.SEXY.items = []
      this.enabledGameProvider.SEXY.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.SEXY.page}&page_size=15&game_provider=AE`)
        this.enabledGameProvider.SEXY.items = betDetails.data
        this.enabledGameProvider.SEXY.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.SEXY.items = []
        this.enabledGameProvider.SEXY.totalRows = 0
      }
    },
    async fetchWM(query) {
      if (!this.enabledGameProvider.WM.enable)
        return
      this.enabledGameProvider.WM.items = []
      this.enabledGameProvider.WM.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.WM.page}&page_size=15&game_provider=WM`)
        this.enabledGameProvider.WM.items = betDetails.data
        this.enabledGameProvider.WM.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.WM.items = []
        this.enabledGameProvider.WM.totalRows = 0
      }
    },
    async fetchDG(query) {
      if (!this.enabledGameProvider.DG.enable)
        return
      this.enabledGameProvider.DG.items = []
      this.enabledGameProvider.DG.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.DG.page}&page_size=15&game_provider=DG`)
        this.enabledGameProvider.DG.items = betDetails.data
        this.enabledGameProvider.DG.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.DG.items = []
        this.enabledGameProvider.DG.totalRows = 0
      }
    },
    async fetchJOKER(query) {
      if (!this.enabledGameProvider.JOKER.enable)
        return
      this.enabledGameProvider.JOKER.items = []
      this.enabledGameProvider.JOKER.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.JOKER.page}&page_size=15&game_provider=JOKER`)
        this.enabledGameProvider.JOKER.items = betDetails.data
        this.enabledGameProvider.JOKER.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.JOKER.items = []
        this.enabledGameProvider.JOKER.totalRows = 0
      }
    },
    async fetchPG(query) {
      if (!this.enabledGameProvider.PG.enable)
        return
      this.enabledGameProvider.PG.items = []
      this.enabledGameProvider.PG.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.PG.page}&page_size=15&game_provider=PG`)
        this.enabledGameProvider.PG.items = betDetails.data
        this.enabledGameProvider.PG.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.PG.items = []
        this.enabledGameProvider.PG.totalRows = 0
      }
    },
    async fetchCQ9(query) {
      if (!this.enabledGameProvider.CQ9.enable)
        return
      this.enabledGameProvider.CQ9.items = []
      this.enabledGameProvider.CQ9.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.CQ9.page}&page_size=15&game_provider=CQ9`)
        this.enabledGameProvider.CQ9.items = betDetails.data
        this.enabledGameProvider.CQ9.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.CQ9.items = []
        this.enabledGameProvider.CQ9.totalRows = 0
      }
    },
    async fetchMG(query) {
      if (!this.enabledGameProvider.MG.enable)
        return
      this.enabledGameProvider.MG.items = []
      this.enabledGameProvider.MG.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.MG.page}&page_size=15&game_provider=MG`)
        this.enabledGameProvider.MG.items = betDetails.data
        this.enabledGameProvider.MG.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.MG.items = []
        this.enabledGameProvider.MG.totalRows = 0
      }
    },
    async fetchJDB(query) {
      if (!this.enabledGameProvider.JDB.enable)
        return
      this.enabledGameProvider.JDB.items = []
      this.enabledGameProvider.JDB.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.JDB.page}&page_size=15&game_provider=JDB`)
        this.enabledGameProvider.JDB.items = betDetails.data
        this.enabledGameProvider.JDB.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.JDB.items = []
        this.enabledGameProvider.JDB.totalRows = 0
      }
    },
    async fetchAMB(query) {
      // this.SAitems = []
      // this.totalSArows = 0
    },
    async fetchHOTGRAPH(query) {
      if (!this.enabledGameProvider.HOTGRAPH.enable)
        return
      this.enabledGameProvider.HOTGRAPH.items = []
      this.enabledGameProvider.HOTGRAPH.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.HOTGRAPH.page}&page_size=15&game_provider=HOTGRAPH`)
        this.enabledGameProvider.HOTGRAPH.items = betDetails.data
        this.enabledGameProvider.HOTGRAPH.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.HOTGRAPH.items = []
        this.enabledGameProvider.HOTGRAPH.totalRows = 0
      }
    },
    async fetchSBO(query) {
      if (!this.enabledGameProvider.SBO.enable)
        return
      this.enabledGameProvider.SBO.items = []
      this.enabledGameProvider.SBO.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&platform=WALLET&page=${this.enabledGameProvider.SBO.page}&page_size=15&game_provider=SBO`)
        this.enabledGameProvider.SBO.items = betDetails.data
        this.enabledGameProvider.SBO.totalRows = betDetails.meta.total
      } catch (err) {
        this.enabledGameProvider.SBO.items = []
        this.enabledGameProvider.SBO.totalRows = 0
      }
    },
    format_sbo_betdetail(data, kind) {
      let htmlResult = ''
      const statusMap = {
        won: 'ชนะ',
        lose: 'แพ้',
        tie: 'เสมอ',
        draw: 'เสมอ',
      }
      if (kind === 'subbet') {
        Object.values(JSON.parse(data.subbet || '[]')).forEach((sbo, idx) => {
          if (idx > 0)
            htmlResult += '<br><br>'
          htmlResult += `<div><span style="font-weight: 800;">แทง</span> ${sbo.betOption}</div>`
          htmlResult += `<div><span style="font-weight: 800;">แบบ</span> ${sbo.marketType}</div>`
          htmlResult += `<div><span style="font-weight: 800;">กีฬา</span> ${sbo.sportType}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ต่อรอง</span> ${sbo.hdp}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ค่าน้ำ</span> ${sbo.odds}</div>`
          htmlResult += `<div><span style="font-weight: 800;">คู่</span> ${sbo.match}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ลีค</span> ${sbo.league}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ครึ่งแรก</span> ${sbo.htScore}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ครึ่งหลัง</span> ${sbo.ftScore}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ผล</span> ${statusMap[sbo.status]}</div>`
        })
      } else if (kind === 'detail') {
        const detail = JSON.parse(data.detail || '{}')
        const statusColorMap = {
          won: 'lime',
          lose: 'red',
          tie: 'white',
          draw: 'white',
        }
        htmlResult += `<div><span style="font-weight: 800;">ผลบิล</span> <span style="color: ${statusColorMap[detail.status]};">${statusMap[detail.status]}</span></div>`
        htmlResult += `<div><span style="font-weight: 800;">จำนวน</span> ${this.numberWithCommas(detail.winLost)} บาท</div>`
        // htmlResult += `<div><span style="font-weight: 800;">กีฬา</span> ${detail.sportType}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ต่อรอง</span> ${detail.hdp}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ค่าน้ำ</span> ${sbo.odds}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">คู่</span> ${sbo.match}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ลีค</span> ${sbo.league}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ครึ่งแรก</span> ${sbo.htScore}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ครึ่งหลัง</span> ${sbo.ftScore}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ผล</span> ${sbo.status}</div>`
      }
      return htmlResult
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.walletTxn = []
      const ctx = this
      Object.keys(this.enabledGameProvider).forEach(val => {
        ctx.enabledGameProvider[val].items = []
        ctx.enabledGameProvider[val].totalRows = 0
      })
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      if (this.tableQuery.phoneno === null || this.tableQuery.phoneno === undefined) {
        this.items = []
        return this.items
      }

      const { data: _wallettx } = await this.$http.get(`/userwallet/transactions${query !== '' ? (`?${query}`) : ''}`)
      this.items = _wallettx.message.data
      this.totalRows = _wallettx.message.total
      return this.items
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.tableQuery.page = 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.td-currency {
  text-align: right;
}
</style>
